<template>
  <div class="flex justify-center container">
    <div
      class="begin bg-white rounded-md py-3 w-11/12 md:w-4/5 lg:w-2/5 mx-auto my-3"
    >
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">Register</div>
          <div class="card-body">
            <div v-if="error" class="alert text-red-600">{{ error }}</div>
            <form action="#" @submit.prevent="Register">
              <div class="form-group row">
                <label for="name" class="label font-normal mb-2 text-gray-500"
                  >Name</label
                >

                <div class="col-md-6">
                  <input
                    id="name"
                    type="name"
                    class="form-control w-full border rounded-md h-11 border-gray-400 p-4"
                    name="name"
                    value
                    required
                    autofocus
                    v-model="name"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="email" class="label font-normal mb-2 text-gray-500"
                  >Email</label
                >

                <div class="col-md-6">
                  <input
                    id="email"
                    type="email"
                    class="form-control w-full border rounded-md h-11 border-gray-400 p-4"
                    name="email"
                    value
                    required
                    autofocus
                    v-model="email"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label
                  for="password"
                  class="label font-normal mb-2 text-gray-500"
                  >Password</label
                >

                <div class="col-md-6">
                  <input
                    id="password"
                    type="password"
                    class="form-control w-full border rounded-md h-11 border-gray-400 p-4"
                    name="password"
                    required
                    v-model="password"
                  />
                </div>
              </div>

              <div class="flex justify-end mt-5">
                <div class="col-md-8 offset-md-4">
                  <button
                    type="submit"
                    class="bg-identityColor hover:bg-yellow-600 hover:text-gray-200 py-3 md:px-14 px-5 text-white font-semibold rounded-md ml-3"
                  >
                    Register
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "RegisterComponent",
  setup() {
    const name = ref("");
    const email = ref("");
    const password = ref("");
    const error = ref(null);

    const store = useStore();
    const router = useRouter();

    const Register = async () => {
      try {
        await store.dispatch("register", {
          email: email.value,
          password: password.value,
          name: name.value,
        });
        console.log("Email user:", email.value);
        router.push("/lBLogin");
      } catch (err) {
        error.value = err.message;
        console.log("Erreur :", err);
      }
    };

    return { Register, name, email, password, error };
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";

.op {
  color: #909090;
}
.ip {
  color: #7f8896;
}
</style>
